<template>
  <v-container id="urbran-renvewal-detail" fluid tag="section">
    <v-row justify="center">
        <dialog-message
          :dialog_message="dialog_message"
          :message="message"
        ></dialog-message>
        <v-dialog
          max-width="800px"
          v-model="itemDialog"
        >
          <v-card>
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    class="mx-2"
                    v-model="currentItem.land_area"
                    type='number'
                    :label="$t('land.land_area')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="text-right">
                  <v-btn
                    color="success"
                    class="mr-0"
                    @click="saveItem()"
                    >{{ $t("common.confirm") }}{{ $t("common.edit") }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
        <v-dialog
          max-width="800px"
          persistent
          v-model="listResponse"
        >
          <v-card>
            <v-container>
              <v-row>
                <template v-if="listLoading">
                  <v-col cols="12" class="text-center">
                    <v-progress-circular
                      :size="70"
                      :width="7"
                      color="primary"
                      indeterminate
                    ></v-progress-circular>
                  </v-col>
                  <v-col cols="12" class="text-center text-h3">
                    {{ $t('common.waiting') }}
                  </v-col>
                </template>
                <template v-else>
                  <v-col cols="12">
                    <owner-list-items
                      :list-items="listItems"
                      :no-data="no_data"
                      :price="price"
                    >
                    </owner-list-items>
                    <v-divider />
                    <div class="text-right">{{ $t('shopping.totalAmount') }}：{{ price }}</div>
                  </v-col>
                </template>
              </v-row>
            </v-container>
            <v-card-actions class="justify-end pt-0">
              <v-btn
                text
                color="grey"
                @click="closeList"
              >
                {{ $t('common.cancel') }}
              </v-btn>
              <v-btn
                v-if="!listLoading"
                text
                color="primary"
                @click="addToCart"
              >
                {{ $t('shopping.purchase') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="timeoutDialog"
          max-width="400px"
          @click:outside="closeTimeout"
        >
          <v-card>
            <v-card-title class="headline grey lighten-2" primary-title>
              {{ $t("transcript.timeout") }}
            </v-card-title>
            <template v-for="(text, index) in timeoutTexts">
              <v-card-text :key="index">
                {{ text }}
              </v-card-text>
            </template>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="closeTimeout"> OK </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      <v-col cols="12" md="10">
        <base-material-card>
          <template v-slot:heading>
            <div v-if="createUrban" class="font-weight-light text-center text-h3 font-weight-medium">{{ $t('urbanRenewal.createUrban') }}</div>
            <div v-else class="font-weight-light text-center text-h3 font-weight-medium">{{ item.name }}</div>
          </template>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container class="py-0">
              <v-row>
                <v-col cols="12" class="text-right">
                  {{ $t('urbanRenewal.landInfoCount') }}：{{ landInfoCount }}
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    ref="urban_name"
                    type='text'
                    :readonly="name_disabled"
                    :label="$t('urbanRenewal.name')" 
                    v-model="item.name" />
                </v-col>

                <v-card>
                  <v-row>
                    <v-col cols="12" md="4">
                      <!-- <v-text-field
                        :rules="fieldRules"
                        :label="$t('urbanRenewal.city_name')" v-model="item.city_name" /> -->
                        <v-select
                          class="mx-2"
                          :label="$t('urbanRenewal.city_name')"
                          :items="city_info"
                          v-model="city_code"
                          v-on:change="getDistrictsApi"
                        :rules="fieldRules"
                        ></v-select>
                    </v-col>

                    <v-col cols="12" md="4">
                      <!-- <v-text-field
                        :rules="fieldRules"
                        class="purple-input"
                        :label="$t('urbanRenewal.area_name')"
                        v-model="item.area_name"
                      /> -->
                      <v-select
                        class="mx-2"
                        :label="$t('urbanRenewal.area_name')"
                        :items="district_info"
                        v-model="district_code"
                        @change="getRegionApi"
                        :item-text="(item) => item.name"
                        item-value="code"
                        :rules="fieldRules"
                      ></v-select>
                    </v-col>

                    <v-col cols="12" md="4">
                      <!-- <v-text-field
                        :rules="fieldRules"
                        :label="$t('urbanRenewal.region_name')"
                        class="purple-input"
                        v-model="item.region_name"
                      /> -->
                      <v-select
                        class="mx-2"
                        :label="$t('urbanRenewal.region_name')"
                        :items="region_info"
                        v-model="region_code"
                        :item-text="getRegionDisplay"
                        item-value="code"
                        :rules="fieldRules"
                      ></v-select>
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-text-field
                        class="mx-2"
                        :label="$t('urbanRenewal.landMajorNumber')"
                        :rules="landNoRules"
                        v-model="landMajorNumber" />
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-text-field
                        class="purple-input mx-2"
                        :rules="landNoRules"
                        :label="$t('urbanRenewal.landMinorNumber')"
                        v-model="landMinorNumber"
                      />
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-text-field
                        :label="$t('land.land_area')"
                        type="number"
                        class="purple-input mx-2"
                        v-model="land_area"
                      />
                    </v-col>

                    <v-col cols="12" class="text-right">
                      <v-btn color="primary" dark class="mb-2 mx-2" @click="addLandInfo()">
                        <v-icon left>mdi-pencil-plus</v-icon>
                        {{ $t("urbanRenewal.createLandInfo") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card>

                <v-col cols="12">
                  <v-data-table
                    :headers="headers"
                    :items="landInfo"
                    :items-per-page="-1"
                    :loading="loading"
                    multi-sort
                  >
                  <template v-slot:item="{ item }">
                    <tr v-if="!item.disabled">
                      <td>
                        <v-flex>
                          <span>{{ item.land_name }}{{ item.land_no.split('_').pop() }}</span>
                        </v-flex>
                      </td>
                      <td>
                        <v-flex>
                          <span>{{ areaFormat(item.land_area) }}</span>
                        </v-flex>
                      </td>
                      <td>
                        <v-flex>
                          <v-btn
                            v-if="!item.representative"
                            color="primary"
                            class="mx-2"
                            small
                            @click="setRepresentative(item)"
                            >{{ $t("urbanRenewal.setRepresentative") }}</v-btn
                          >
                          <v-btn
                            v-if="item.representative"
                            color="ocean"
                            class="mx-2 px-5"
                            small
                            >{{ $t("urbanRenewal.representative") }}</v-btn
                          >
                          <v-btn 
                            color="orange" 
                            dark 
                            class="mx-2" 
                            small
                            @click="openDialog(item)"
                          >
                            {{ $t("common.edit") }}
                          </v-btn>
                          <v-btn
                            color="red"
                            class="mx-2"
                            small
                            @click="RemoveLandInfo(item)"
                            >{{ $t("common.delete") }}</v-btn
                          >
                        </v-flex>
                      </td>
                    </tr>
                  </template>
                  <template slot="no-data">
                    <div>
                      {{ $t('common.noData') }}
                    </div>
                  </template>
                  </v-data-table>
                </v-col>

                <v-col cols="12" v-if="!createUrban" class="text-right">
                  <div 
                    v-if="ongoingCount!=0"
                    class="d-inline-flex red--text text-h3"
                  >
                    {{ $t('transcript.ongoingCount') }}： {{ ongoingCount }}
                  </div>
                  <v-progress-linear
                    v-if="ongoingCount!=0"
                    v-model="task_progress"
                    color="light-blue"
                    height="15"
                    striped
                  >
                    <template v-slot:default="{ value }">
                      <strong v-if="value!=99">{{ Math.floor(value) }}%</strong>
                      <strong v-else class="white--text">{{ $t('transcript.almostDone') }}</strong>
                    </template>
                  </v-progress-linear>
                  <div 
                    v-if="Object.keys(timeout).length!=0"
                    class="d-inline-flex red--text text-h3 mx-2"
                    style="cursor: pointer"
                    @click="showTimeout"
                  >
                    {{ $t('transcript.timeout') }}： {{ Object.keys(timeout).length }}
                  </div>
                  <v-btn color="primary" dark class="mb-2 mx-2"
                    v-if="ongoingCount==0" :disabled="loading" @click="getAllLandList">{{ $t('transcript.createLandTask') }}</v-btn>
                  <v-btn
                    dark
                    v-if="ongoingCount==0&&enableBuildingTask"
                    color="ocean"
                    class="mb-2 mx-2"
                    :disabled="loading"
                    @click="getAllBuildingList"
                  >
                    {{ $t('transcript.createBuildingTask') }}
                  </v-btn>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('urbanRenewal.contact_name')"
                    class="purple-input"
                    v-model="item.contact_name"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    class="purple-input"
                    :label="$t('urbanRenewal.contact_phone')"
                    v-model="item.contact_phone"
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    class="purple-input"
                    :label="$t('urbanRenewal.contact_address')"
                    v-model="item.contact_address"
                  />
                </v-col>

                <v-col cols="12"
                  v-if="role===UserRole.CompanyUser&&!createUrban"
                >
                  <v-text-field
                    class="purple-input"
                    readonly
                    :label="$t('urbanRenewal.host')"
                    v-model="company_user"
                  />
                </v-col>

                <v-col cols="12"
                  v-if="role===UserRole.CompanyAdmin"
                >
                  <v-select
                    class="assign-select"
                    v-model="relation"
                    :items="users"
                    :menu-props="{ maxHeight: '400' }"
                    :label="$t('urbanRenewal.host')"
                    item-value="id"
                    multiple
                    hide-details
                  >
                    <template slot="selection" slot-scope="data">
                      {{ data.item.username }}
                    </template>
                    <template slot="item" slot-scope="data">
                      {{ data.item.nickname }}({{ data.item.username }})
                    </template></v-select>
                </v-col>

                <v-col cols="12" class="text-right">
                  <v-btn color="aqua" class="mx-2" @click="back()">{{ $t("common.back") }}</v-btn>
                  <v-btn v-if="createUrban" color="success" class="mx-2" :disabled="loading" @click="save()">{{ $t("common.save") }}</v-btn>
                  <v-btn v-else color="success" class="mx-2" @click="patchApi()">{{ $t("common.save") }}</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import CommonUtil from "@/utils/commonutil.js";
import { UserRole, ProductType } from "@/definition"
import OwnerListItems from "./OwnerListItems.vue"
export default {
  data: vm => ({
    UserRole: UserRole,
    ProductType: ProductType,
    itemDialog: false,
    createUrban: false,
    urban_id: null,
    landInfoCount: 0,
    loading: false,
    landMajorNumber: '',
    landMinorNumber: '',
    land_area: null,
    item_land_area: null,
    currentItem: {},
    dialog_message: false,
    valid: true,
    landInfo: [],
    headers: [
      {
        sortable: false,
        text: vm.$i18n.t("land.land_no"),
        value: "land_no",
      },
      {
        sortable: false,
        text: vm.$i18n.t("land.land_area"),
        value: "land_area",
      },
      {
        sortable: false,
        // text: vm.$i18n.t("common.actions"),
        text: '',
        value: "actions",
      },
    ],
    item: {
      name:null,
      city_name: null,
      area_name: null,
      region_name: null,
      cdr_code: null,
      owner_count: 0,
      contact_name: null,
      contact_phone: null,
      contact_address: null
    },
    fieldRules: [v => !!v || vm.$i18n.t("common.missing")],
    landNoRules: [v => v.length==4 || vm.$i18n.t("land.invalid")],
    relationRules: [v => v.length!=0 || vm.$i18n.t("common.missing")],
    message: {
      title: "",
      text: ""
    },
    city_info: [
      { text: "臺北市", value: "A" },
      { text: "臺中市", value: "B" },
      { text: "基隆市", value: "C" },
      { text: "臺南市", value: "D" },
      { text: "高雄市", value: "E" },
      { text: "新北市", value: "F" },
      { text: "宜蘭縣", value: "G" },
      { text: "桃園市", value: "H" },
      { text: "嘉義市", value: "I" },
      { text: "新竹縣", value: "J" },
      { text: "苗栗縣", value: "K" },
      { text: "南投縣", value: "M" },
      { text: "彰化縣", value: "N" },
      { text: "新竹市", value: "O" },
      { text: "雲林縣", value: "P" },
      { text: "嘉義縣", value: "Q" },
      { text: "屏東縣", value: "T" },
      { text: "花蓮縣", value: "U" },
      { text: "臺東縣", value: "V" },
      { text: "金門縣", value: "W" },
      { text: "澎湖縣", value: "X" },
      { text: "連江縣", value: "Z" },
    ],
    district_info: [],
    region_info: [],
    city_code: null,
    district_code: null,
    region_code: null,
    name_disabled: true,
    company_user: "",
    users: [],
    relation: [],
    ongoingCount: 0,
    enableBuildingTask: false,
    listResponse: false,
    listLoading: false,
    listItems: [],
    no_data: [],
    lbtype: 'L',
    price: 0,
    transcript_price: 10,
    land_name_list: {},
    task_id: null,
    waitNewestCount: 41,
    timeout: [],
    timeoutDialog: false,
    timeoutTexts: [],
    valuation_id: null,
    task_progress: 0,
    updateProgress: null,
  }),
  components:{
    OwnerListItems
  },
  computed: {
    ...mapState(["token", "company", "role"])
  },
  beforeDestroy () {
    if(this.updateProgress){
      clearInterval(this.updateProgress)
    }
  },
  methods: {
    ...mapMutations(['setTitle', 'addShoppingItems', 'SET_SHOPPING_CART']),
    getApi() {
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/urban/" + this.urban_id + "/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token
        },
      };
      this.axios
        .get(url, config)
        .then(function(response) {
          currentObj.loading = false;
          currentObj.setResult(response.data);
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("get-api-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    getUsers() {
      if(this.role !== UserRole.CompanyAdmin){
        return
      }
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/company/getUsers/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token
        },
      };
      this.axios
        .get(url, config)
        .then(function(response) {
          currentObj.loading = false;
          currentObj.setUser(response.data);
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("get-api-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      // create hn account
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/urban/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token
        }
      };
      let data = {
        name: this.item.name,
        city_name: this.item.city_name,
        area_name: this.item.area_name,
        region_name: this.item.region_name,
        cdr_code: this.item.cdr_code,
        owner_count: this.item.owner_count,
        contact_name: this.item.contact_name,
        contact_phone: this.item.contact_phone,
        contact_address: this.item.contact_address,
        land_info: this.landInfo,
        relation: this.relation
      };
      this.axios
        .post(url, data, config)
        .then(function(response) {
          currentObj.loading = false;
          alert("送出成功");
          currentObj.back();
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$i18n.t("get-api-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    patchApi() {
      if (this.item.name==''|| this.landInfoCount==0) {
        return;
      }
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL + "/backend/urban/" + this.item.id + "/";

      const jsonData = {
        name: this.item.name,
        city_name: this.item.city_name,
        area_name: this.item.area_name,
        region_name: this.item.region_name,
        cdr_code: this.item.cdr_code,
        contact_name: this.item.contact_name,
        contact_phone: this.item.contact_phone,
        contact_address: this.item.contact_address,
        land_info: this.landInfo,
        relation: this.relation
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token
        }
      };
      this.axios
        .patch(url, jsonData, config)
        .then(function(response) {
          alert("更新成功。");
          currentObj.back();
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("update-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
      this.dialog_form = false;
    },
    back(item) {
      this.$router.push({
        name: "UrbanRenewalTables",
      });
    },
    setResult(data){
      this.item = data.urban;
      this.users = data.company_users;
      this.landInfo = data.land_info;
      this.ongoingCount = data.ongoingTask.length;
      if(this.ongoingCount >0){
        let _this = this
        this.updateProgress = setInterval(() => {
          _this.setProgress(data.ongoingTask[0].create_time, data.ongoingTask[0].expect_time)
        }, 3000)
      }
      this.enableBuildingTask = data.enableBuildingTask;
      this.landInfoCount = this.countLandInfo()
      this.landInfo.forEach(element=>{
        element['land_name'] = element.region.city.name + element.region.district.name + element.region.name
        let region = element.land_no.substring(0,9)
        if(Object.keys(this.land_name_list).indexOf(region)==-1){
          this.land_name_list[region] = element['land_name']
        }
      })
      this.timeout = data.timeoutTask;
      let task_ids = Object.keys(data.timeoutTask)
      for (let task_id = 0; task_id < task_ids.length; task_id++){
        let key_list = data.timeoutTask[task_ids[task_id]]
        for (let key = 0; key < key_list.length; key++){
          let item_region = key_list[key].substring(0,9)
          let lbkey = this.land_name_list[item_region] + key_list[key].substring(10, key_list[key].length)
          key_list[key] = lbkey
        }
      }
      if(data.relation){
        let username_list = []
        data.relation.forEach(element=>{
          let user = this.users.find(item=>{ return item.id == element })
          if(user){username_list.push(user.username)}
        })
        this.company_user = username_list.toString()
        this.relation = data.relation;
      }
    },
    setUser(data){
      this.users = data;
    },
    setRepresentative(item){
      this.landInfo.forEach(element => {
        if(element.representative == true){
          element.representative = false
        }
      });
      item.representative = true
      this.setUrbanName(item)
    },
    setProgress(startTime, endTime){
      console.log('update progress')
      let totalTime = new Date(endTime)-new Date(startTime)
      let timePassed = new Date()-new Date(startTime)
      if (timePassed > totalTime){
        this.task_progress = 99
        clearInterval(this.updateProgress)
      } else {
        this.task_progress = (timePassed / totalTime)*100
      }
    },
    RemoveLandInfo(item){
      if(item.removeable==false){
        this.message.title = this.$t("remove-fail");
        this.message.text = "有所有權人持有此地號。若需刪除，請先移除所有權人之持有地號";
        this.dialog_message = !this.dialog_message;
        return;
      }
      let change_representative = false;
      let representative_item = this.landInfo.find(element => {
        return element.representative == true;
      });
      let info_index = this.landInfo.findIndex(element => {
        return element.land_no == item.land_no;
      });
      if (representative_item.land_no == item.land_no){
        change_representative = true
      }
      if(item.id){
        item.disabled = true;
      } else {
        this.landInfo.splice(info_index,1)
      }
      this.landInfoCount = this.countLandInfo()

      // change urban name
      if (change_representative == true){
        let first_info = this.landInfo.find(element => {
          return element.disabled == false;
        });
        this.setRepresentative(first_info)
      } else {
        this.setRepresentative(representative_item)
      }
    },
    countLandInfo(){
      let count = 0;
      this.landInfo.forEach(element=>{
        if(element['disabled']==false){
          count +=1
        }
      })
      return count
    },
    addLandInfo(){
      if (!this.$refs.form.validate()) {
        return;
      }
      let land_name = this.getLandName()
      let land_no = this.city_code + "_" + this.district_code + "_" + this.region_code + "_" + this.landMajorNumber + "-" + this.landMinorNumber
      let info_index = this.landInfo.findIndex(element => {
        return element.land_no == land_no;
      });
      if(info_index>=0){
        this.message.title = this.$t("錯誤訊息");
        this.message.text = "已存在的地號";
        this.dialog_message = !this.dialog_message;
        return;
      }
      let land_area = this.land_area?parseFloat(this.land_area).toFixed(2):null;
      let land_region = this.region_info.find(element=>{
        return element.code == this.region_code
      })

      let is_representative = false;
      if (this.landInfoCount == 0){
        is_representative = true
      }

      let newLandInfo = {
        'land_no': land_no,
        'land_area': land_area,
        'representative': is_representative,
        'land_name': land_name,
        'urban_renewal': this.urban_id,
        'region_id': land_region.id,
        'id': null,
        'disabled': false
      }
      this.landInfo.push(newLandInfo)
      this.landInfoCount = this.countLandInfo()

      let representative = null;
      if (this.landInfoCount == 1){
        representative = newLandInfo
      } else {
        representative = this.landInfo.find(element=>{
          return element.representative == true
        })
      }
      this.name_disabled = false;
      this.setUrbanName(representative)
    },
    setUrbanName(item){
      this.item.city_name = item.land_no.split('_')[0]
      this.item.area_name = item.land_no.split('_')[1]
      this.item.region_name = item.land_no.split('_')[2]
      this.item.cdr_code = this.item.city_name + "_" + this.item.area_name + "_" + this.item.region_name
      let land_no = item.land_no.split('_').pop()
      let major_no = land_no.split('-')[0]
      let minor_no = land_no.split('-')[1]
      let urban_name = item.land_name+this.getLandNo(major_no, 'major')+this.getLandNo(minor_no, 'minor')+"地號等"+this.landInfoCount+"筆土地都市更新會"
      this.item.name = urban_name
      // this.$refs.urban_name.value = urban_name
    },
    getLandName(){
      let city = this.city_info.find(element=>{
        return element.value == this.city_code
      })
      let area = this.district_info.find(element=>{
        return element.code == this.district_code
      })
      let region = this.region_info.find(element=>{
        return element.code == this.region_code
      })
      let land_name = city.text + area.name + region.name
      return land_name
    },
    getLandNo(land_no, no_type){
      let return_no = Number(land_no).toString()
      if (return_no == '0'){
        return ""
      } else {
        if (no_type == 'minor'){
          return_no = "-"+return_no
        }
        return return_no
      }
    },
    getDistrictsApi() {
      this.region_info = [];
      const currentObj = this;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/districts/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token
        },
        params: {
          city__code: this.city_code,
        },
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          currentObj.district_code = null;
          currentObj.region_code = null;
          currentObj.district_info = response.data;
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    getRegionApi() {
      const currentObj = this;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/regions/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token
        },
        params: {
          city__code: this.city_code,
          district__code: this.district_code,
        },
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          currentObj.region_code = null;
          currentObj.region_info = response.data;
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    getRegionDisplay(item) {
      return item.name;
    },
    areaFormat(area){
      return CommonUtil.areaFormat(area)
    },
    openDialog(item) {
      this.currentItem = Object.assign({}, item);
      this.currentItem.land_area = item.land_area?parseFloat(item.land_area).toFixed(2):null;
      this.itemDialog = !this.itemDialog
    },
    saveItem() {
      let change_item = this.landInfo.find((element)=>{return element.land_no == this.currentItem.land_no})
      if(this.currentItem.land_area == ""){
        this.currentItem.land_area = null
      }
      Object.assign(change_item, this.currentItem)
      this.itemDialog = !this.itemDialog
    },
    checkTaskStatus(task_id){
      if (this.waitNewestCount == 0){
        alert(this.$i18n.t('transcript.listApiFailed'))
        this.listLoading = false;
        this.listResponse = false;
        return
      }
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/valuation/checkTaskStatus/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token
        },
        params: {
          task_id: task_id,
        },
      };
      this.axios
        .get(url, config)
        .then(function(response) {
          console.log(response)
          let response_data = response.data
          console.log(response_data)
          if (response_data['status'] == "OK"){
            currentObj.showListItems(response_data['results'], response_data['no_data'])
          } else if (response_data['status'] == "Failed"){
            currentObj.message.title = currentObj.$i18n.t("get-api-fail");
            currentObj.message.text = response_data['msg'];
            currentObj.dialog_message = !currentObj.dialog_message;
          } else if(currentObj.listResponse){
            currentObj.waitNewest(task_id)
          }
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$i18n.t("get-api-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    getAllLandList() {
      this.lbtype = "L"
      let data = {
        lbtype: "L",
        lbkeys: [],
        getAll: true,
        newest: false,
      };
      this.getList(data)
    },
    getAllBuildingList(){
      this.lbtype = "B"
      let data = {
        lbtype: "B",
        lbkeys: [],
        getAll: true,
        newest: false,
      };
      this.getList(data)
    },
    getList(data){
      this.listLoading = true;
      this.listResponse = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/urban/" + this.urban_id + "/getOwnersList/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token
        },
      };
      this.axios
        .post(url, data, config)
        .then(function(response) {
          currentObj.handleListResponse(response.data)
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$i18n.t("get-api-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    handleListResponse(response){
      console.log(response)
      switch(response['status']){
        case 'OK':
          this.task_id = null
          this.valuation_id = response['valuation_id']
          this.showListItems(response['results'])
          break;
        case 'get Newest':
          this.task_id = response['task_id']
          this.waitNewestCount = 41
          this.valuation_id = null
          this.waitNewest(response['task_id'])
          break;
        default:
          alert(this.$i18n.t('transcript.listApiFailed'))
          this.listLoading = false;
          this.listResponse = false;
          break;
      }
    },
    showListItems(results){
      this.listItems = this.transKeyName(results);
      this.price = 0
      this.listItems.forEach(element=>{
        this.price += (element.owners_num * this.transcript_price)
      })
      this.listLoading = false;
    },
    waitNewest(task_id){
      let _this = this
      this.waitNewestCount--
      setTimeout(()=>{
        _this.checkTaskStatus(task_id)
      }, 3000)
    },
    transKeyName(key_list){
      let new_array = []
      let no_data = []
      // key_list.forEach(element=>{
      //   console.log(typeof element)
      //   if (typeof element == 'string'){
      //     let item_region = element.substring(0,9)
      //     new_array.push(this.land_name_list[item_region] + element.substring(10, element.length))
      //   } else {
      //     let item_region = element.lbkey.substring(0,9)
      //     element.lbkey = this.land_name_list[item_region] + element.lbkey.substring(10, element.lbkey.length)
      //   }
      // })
      Object.keys(key_list).forEach(key=>{
        let item_region = key.substring(0,9)
        let lbkey = this.land_name_list[item_region] + key.substring(10, key.length)
        if(key_list[key]['no_data']){
          no_data.push(lbkey)
        } else {
          let new_item = key_list[key]['result']
          new_item['lbkey'] = lbkey
          new_array.push(new_item)
        }
      })
      this.no_data = no_data

      return new_array
    },
    closeList(){
      this.listResponse = false;
    },
    closeTimeout() {
      this.timeoutDialog = false;
    },
    addToCart(){
      let product_name = ''
      let lb_str = ''
      if(this.lbtype=='L'){
        product_name = this.$i18n.t('transcript.createLandTask')
        lb_str = this.$i18n.t('vote.land')
      } else {
        product_name = this.$i18n.t('transcript.createBuildingTask')
        lb_str = this.$i18n.t('vote.building')
      }
      let product_description = this.listItems[0].lbkey + '等' + this.listItems.length + '筆' + lb_str + '謄本'
      let data = {
        id: null,
        name: product_name,
        descriptions: product_description,
        price: this.price,
        product_type: ProductType.Transcript,
        task_id: this.task_id,
      }
      data['valuation_id'] = this.valuation_id
      this.addShoppingItems(data)
      this.SET_SHOPPING_CART(true)
      this.closeList()
    },
    showTimeout(){
      this.timeoutTexts = []
      let task_ids = Object.keys(this.timeout)
      for (let i = 0; i < task_ids.length; i++){
        let text = this.$i18n.t("transcript.task")
        text += task_ids[i]
        text += ' :'
        this.timeoutTexts.push(text)
        let key_list = this.timeout[task_ids[i]]
        this.timeoutTexts.push(key_list.toString())
      }
      this.timeoutTexts.push(this.$i18n.t("transcript.taskTimeout"))
      this.timeoutDialog = !this.timeoutDialog;
    }
  },
  mounted() {
    this.setTitle(this.$i18n.t('title.UrbanRenewalDetail'));
    if (this.$route.query.item) {
      this.urban_id = this.$route.query.item
      this.getApi();
      this.name_disabled = false;
    } else {
      this.createUrban = true;
      this.name_disabled = true;
      this.getUsers()
    }
  }
};
</script>
