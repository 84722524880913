<template>
    <v-list three-line>
        <template v-for="(item, index) in listItems">
            <v-list-item :key="index">
                <v-list-item-title>
                    {{ item.lbkey }}
                </v-list-item-title>
                <v-list-item-subtitle>
                    <div>{{ $t('transcript.ownersNum') }}： {{ item.owners_num }}</div>
                    <!-- {{ $t('transcript.creditorsNum') }}： {{ item.creditors_num }} -->
                    {{ $t('transcript.lastQueryTime') }}： {{ TransDate(item.last_query_time) }}
                </v-list-item-subtitle>
            </v-list-item>
        </template>
        <template v-for="item in noData">
            <v-list-item :key="item">
                <v-list-item-title>
                    {{ item }}
                </v-list-item-title>
                <v-list-item-subtitle>
                    {{ $t('common.noData') }}
                </v-list-item-subtitle>
            </v-list-item>
        </template>
    </v-list>
</template>

<script>

export default {
    props: {
      noData: {
        type: Array,
        default: [],
      },
      listItems: {
        type: Array,
        default: [],
      },
    },
    data: vm => ({
    }),
}
</script>
